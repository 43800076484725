<template>
  <b-container class="container-content">
    <div class="page-header">
      <h4 class="page-header-text mb-3">{{ title }}</h4>
      <span>{{
        // isFromOTM
        //   ? $t('form_label.please_choose_maid_first')
        //   : $t('form_label.please_choose_maid_multi')
        $t('form_label.please_choose_maid_first')
      }}</span>
    </div>
    <b-form-row class="mt-4" v-if="selectMode === 'dateFirst' && type === 'one-time'">
      <b-col class="w-100 col-md-3">
        <b-form-group :label="$t('form_label.pick_a_date')" class="mb-0"></b-form-group>
      </b-col>
      <b-col class="d-flex align-items-center w-100 col-md-3">
        <b-form-group :label="$t('form_label.start')" class="mb-0 mr-2 d-flex align-items-center text-center"></b-form-group>
        <b-img
          alt="information"
          src="../../assets/images/information-icon-black.svg"
          style="margin-top: -2px"
          id="start-time-badge"
        ></b-img>
        <b-tooltip target="start-time-badge" placement="top" triggers="hover">
          {{ $t('warning.start_time_must_be_at_least_24') }}
        </b-tooltip> 
      </b-col>
    </b-form-row>
    <b-form-row class="mb-4" v-if="selectMode === 'dateFirst' && type === 'one-time'">
      <b-col class="z-3 w-100 col-md-3">
        <b-input-group>
          <b-form-input
            class="input-date bg-white"
            :placeholder="$t('form_label.pick_a_date_from_calendar')"
            @focus="toggleCalendar"
            v-model="selectedDate"
            readonly
          ></b-form-input>
          <b-input-group-append>
            <b-button
              variant="link"
              class="input-date input-fa-calendar d-block bg-light border"
              style="height: 38px;"
              @click.stop="toggleCalendar"
            >
              <img :src="require('../../assets/images/icons/datepicker.png')" />
            </b-button>
          </b-input-group-append>
        </b-input-group>

        <v-calendar
          v-show="showCalendar"
          :locale="$i18n.locale"
          ref="calendar"
          v-model="date"
          @dayclick="onDateSelected"
          :min-date="minDate"
          :attributes="defaultCalendarAttrs"
          class="calendar-dropdown"
        ></v-calendar>
      </b-col>
      <b-col class="w-100 col-md-3">
        <b-form-group class="mb-0">
          <v-select
            :labelTitle="$t('form_label.nothing_selected')"
            class="custom-v-select"
            :options="timeOptions"
            v-model="selectedTime"
            :disabled="!selectDateFilter"
          />
          <!-- @input="onTimeChange" -->
          <!-- <p>Selected Date: {{ dateFilter }}</p>
          <p>Selected time: {{ selectedTime }}</p>
          <p>Selected date filter: {{ selectDateFilter }}</p> -->
        </b-form-group>
      </b-col>
    </b-form-row>

    <!-- <div class="d-flex my-4 align-items-center" v-if="type === 'multi-package'">
      <button 
        v-for="(percentage, index) in percentages" 
        :key="index" 
        @click="setFilter(percentage)" 
        :class="['btn rounded-0', percentage === selectedPercentage ? 'btn-primary' : 'btn-outline-primary']">
        {{ percentage }}%
      </button>
      <div class="d-flex ml-2">
        <p class="mb-0 mr-2 d-flex align-items-center text-primary">Minimum % of schedule match</p>
        <b-img
          alt="information"
          src="../../assets/images/information-icon-black.svg"
          id="switch-step-info"
        ></b-img>
        <b-tooltip
          target="switch-step-info"
          placement="bottom"
          triggers="hover"
          >{{ $t('pages.booking.percentage_info') }}</b-tooltip
        >
      </div>
    </div> -->

    <FilterMaid :options="filterData" @onOptionsChange="onOptionsChange" :total="total_maids" />
    <b-row>
      <b-col
        v-if="maidsAdminRecommend.length > 0"
        :xl="maidsAdminRecommend.length > 3 ? 12 : maidsAdminRecommend.length * 3"
        :lg="maidsAdminRecommend.length > 2 ? 12 : maidsAdminRecommend.length * 4"
        :md="maidsAdminRecommend.length > 1 ? 12 : maidsAdminRecommend.length * 6"
        cols="12"
        class="recommend-color text-center font-weight-bold text-dark admin-recommend"
      >
        {{ $t('form_label.admin_recommend') }}
      </b-col>
    </b-row>
    <b-row>
      <b-col
        v-if="maidsAdminRecommend.length > 0"
        class="recommend-color"
        cols="12"
        md="6"
        lg="4"
        xl="3"
        v-for="(maid, index) in maidsAdminRecommend"
        :key="`maid-recommend-${index}`"
        :class="getBorderRadiusClass(index, maidsAdminRecommend.length)"
      >
        <maid class="recommend-card" :detail="maid" :hasButton="true" :selectType="selectType" :selectedDate="selectDateFilter" :selectedTime="selectedTime" />
      </b-col>
      <b-col
        cols="12"
        md="6"
        lg="4"
        xl="3"
        v-for="(maid, index) in maids.filter(
          (maid) => !maidsAdminRecommend.some((m) => m.id === maid.id)
        )"
        :key="`maid-${index}`"
      >
        <maid :detail="maid" :hasButton="true" :selectType="selectType" :selectedDate="selectDateFilter" :selectedTime="selectedTime" />
      </b-col>
      <b-col
        cols="12"
        md="6"
        lg="4"
        xl="3"
        v-for="(maid, index) in maidsFBFriend.filter(
          (maid) => !maidsAdminRecommend.some((m) => m.id === maid.id)
        )"
        :key="maidKey('maid-friend', index)"
      >
          <maid :detail="maid" :hasButton="true" :selectType="selectType" :selectedDate="selectDateFilter" :selectedTime="selectedTime" />
      </b-col>
    </b-row>
    <b-row v-if="isFetchingMaid">
      <b-col cols="12" md="6" lg="4" xl="3" v-for="i in 12" :key="i">
        <maid :isLoading="isFetchingMaid" :detail="null" />
      </b-col>
    </b-row>
    <!-- <b-button variant="link" block @click="loadMoreMaids" v-if="showLoadMoreButton">{{
      $t('button.load_more')
    }}</b-button> -->
    <div v-if="isLoading" class="w-100 text-center">
      <LoadingSpinner label="Loading..." style="margin-top: 10px; height: 80px;"></LoadingSpinner>
    </div>
    <div v-if="total_maids === 0" class="text-center">
      <p v-html="$t('no_data.no_maids')"></p>
    </div>
    <div v-if="isNoMaidAvailable && !isFetchingMaid && showLoadMoreButton && total_maids === 0" class="text-center">
      <p v-html="$t('no_data.maid_high_demand')"></p>
      <b-link @click="goBack">{{ $t('button.go_back') }}</b-link>
    </div>
  </b-container>
</template>

<script>
import { Vue, Component, Watch } from 'vue-property-decorator'
import { createNamespacedHelpers } from 'vuex'
import Maid from '../../components/Maid'
import VSelect from '@alfsnd/vue-bootstrap-select'
import FilterMaid from '../../components/FilterMaid'
import LoadingSpinner from '../../components/LoadingSpinner'
import Constant, { ENABLE_FILTER_MAID_BY_USER_LOCATION } from '../../config/booking'
import { filterMaidsMixin } from '../../mixins/filterMaid'
import pick from 'lodash/pick'
import get from 'lodash/get'
import omitBy from 'lodash/omitBy'
// import isEmpty from 'lodash/isEmpty'
import moment from 'moment'
import { isToday, minimumBookingMoment } from '../../utils'

const BookingStore = createNamespacedHelpers('booking')
const MaidStore = createNamespacedHelpers('maid')
const AuthStore = createNamespacedHelpers('auth')
const CalendarStore = createNamespacedHelpers('calendar')

@Component({
  components: { Maid, FilterMaid, LoadingSpinner, VSelect },
  computed: {
    ...AuthStore.mapState(['isLoggedIn']),
    ...BookingStore.mapState(['oneTime', 'multiPackage']),
    ...MaidStore.mapState(['isFetchingMaids', 'isFetchingMaidsFriend']),
    ...MaidStore.mapGetters(['maids', 'maidsFBFriend', 'maidsAdminRecommend', 'totalMaidsFriend', 'totalMaids']),
    ...CalendarStore.mapGetters(['baseDisabledHighlight']),
  },
  methods: {
    ...MaidStore.mapActions(['getMaids', 'getMaidsFriend', 'getMaidsAdminRecommend']),
    ...MaidStore.mapMutations(['resetMaidsList']),
    generateTimeOptions() {
      const times = [];
      const date = new Date();
      date.setDate(date.getDate() + 1);
      const params = this.$route.query
      const { start_datetime, end_datetime } = params

      if (start_datetime && end_datetime && !this.timeList) {
        return Constant.TIME_OPTIONS.filter((t) => {
          return (
            this.$momentTZ(start_datetime).hour(t.hour).minute(t.minute) >
            minimumBookingMoment()
          )
        })
      } 
      else if(this.timeList) {
        return Constant.TIME_OPTIONS.filter((t) => {
            return (
              this.$momentTZ(this.selectDateFilter).hour(t.hour).minute(t.minute) >
              minimumBookingMoment()
            )
          })
      } 
      else {
        return Constant.TIME_OPTIONS.filter((t) => {
            return (
              this.$momentTZ(times).hour(t.hour).minute(t.minute)
            )
          })
      }
    },
    toggleCalendar() {
      this.showCalendar = !this.showCalendar;
      if (this.showCalendar) {
        document.addEventListener("click", this.handleClickOutside);
      } else {
        document.removeEventListener("click", this.handleClickOutside);
      }
    },
    handleClickOutside(event) {
      const calendarElement = this.$refs.calendar ? this.$refs.calendar.$el : null;
      const inputElement = this.$el.querySelector(".input-date");
      
      if (
        calendarElement && !calendarElement.contains(event.target) &&
        inputElement && !inputElement.contains(event.target)
      ) {
        this.showCalendar = false;
        document.removeEventListener("click", this.handleClickOutside);
      }
    },
    onDateSelected(date) {
      this.isLoading = true;
      console.log('date', date)
      const isDisabledDate = this.baseDisabledHighlight
      .some(({ dates }) => {
          const today = isToday(moment(date.dateTime).toISOString())

          return moment(date.id).isSame(moment(dates), 'date') || today
        })
      console.log('isDisabledDate', isDisabledDate)
      if (isDisabledDate) {
        this.isLoading = false;
        return;
      }
      
      const format = this.$i18n.locale === 'th' ? 'ddd DD MMM YYYY' : 'ddd MMM DD, YYYY';
      this.selectedDate =  moment(date.id).format(format);
      this.selectDateFilter = moment.tz(date.id + ' 00:00:00', 'YYYY-MM-DD HH:mm:ss', 'UTC').toISOString();
      this.dateFilter = date.id;
      this.showCalendar = false;
      this.date = new Date(date)

      this.timeList = true;
      // this.timeOptions = this.generateTimeOptions();
      // this.selectedTime = this.timeOptions[0];

      this.resetMaidsList()
      this.getListMaidsAdminRecommend()
      this.loadMoreMaids();
      this.isLoading = false;
      // this.totalMaidPages = -1
      // this.currentMaidsPage = 0
      // this.totalMaidFriendPage = -1
      // this.currentMaidsFriendPage = 0
      
      // this.resetMaidsList();
      // this.getListMaidsAdminRecommend();
      // this.loadMoreMaids();
    },
    formatTime(obj) {
      const hour = String(obj.hour).padStart(2, '0');
      const minute = String(obj.minute).padStart(2, '0');
      return `${hour}:${minute}:00`;
    },
    // setFilter(percentage) {
    //   this.selectedPercentage = percentage;
    // }
  },
  beforeDestroy() {
    document.removeEventListener("click", this.handleClickOutside);
  },
  mixins: [filterMaidsMixin],
})
export default class SelectMaid extends Vue {
  totalMaidPages = -1
  currentMaidsPage = 0
  totalMaidFriendPage = -1
  currentMaidsFriendPage = 0
  isLastPageOfTotalMaid = false
  isLastPageOfMaidFriend = false

  isFetching = false

  waiting = false
  showMaidList = true

  data() {
    return {
      timeOptions: this.generateTimeOptions(),
      showCalendar: false,
      selectedDate: null,
      selectDateFilter: null,
      dateFilter: null,
      selectedTime: null,
      timeList: false,
      selectMode: localStorage.getItem('stepOrder'),
      type: this.$route.query.type,
      calendarFocus: null,
      date: null,
      isLoading: false,
      // percentages: [25, 50, 75, 100],
      // selectedPercentage: 25,
    };
  }

  get minDate() {
    const minDate = new Date()
    minDate.setDate(minDate.getDate() + 1)
    return minDate
  }

  get defaultCalendarAttrs() {
    this.date = new Date(this.dateFilter)
    return [{
      ...this.baseDisabledHighlight, 
      dates: this.date,
      highlight: true,
    }]
  }

  get isFromOTM() {
    console.log('window', window.location.search.includes('one-time'))
    return window.location.search.includes('one-time')
  }

  get showLoadMoreButton() {
    return this.isLoggedIn ? !this.isLastPageOfMaidFriend : !this.isLastPageOfTotalMaid
  }

  get isNoMaidAvailable() {
    return this.isLoggedIn ? this.maidsFBFriend.length === 0 : this.maids.length === 0
  }

  get isFetchingMaid() {
    return this.isFetchingMaids || this.isFetchingMaidsFriend
  }

  // Computed declaration
  get selectType() {
    return this.$route.query.type
  }

  get title() {
    return this.selectType === 'multi-package'
      ? this.$t('commons.book_multi_package')
      : this.$t('commons.book_one_time')
  }

  // fix me - don't forget to load api 20 - maidListWithFriend

  // Methods
  async mounted() {
    window.addEventListener('resize', this.handleResize)
    localStorage.isFromSelectMaid = 'select-maid'
    if (!this.selectType) {
      this.$router.push({ name: 'Home' })
      return
    }
    this.resetMaidsList()
    const callback = this.$route.query.callback
    const isNewBooking =
      callback.includes('book-one-time') || callback.includes('book-multi-package')
    isNewBooking && this.$store.commit('maid/resetMaidHiddenList')

    if (this.$route.query.services) {
      if (
        this.$route.query.services == 'DRAPERY' ||
        this.$route.query.services == 'CLEANING_DRAPERY'
      ) {
        this.filterData.canIron = true
      }
    }

    if (this.withBringEquipmentOptions.bring_equipment) {
      this.filterData.bring_equipment = true
    }

    const dateNow = new Date();
    dateNow.setDate(dateNow.getDate() + 1);

    const params = this.$route.query
    const { start_datetime, end_datetime } = params

    if (start_datetime && end_datetime && this.selectMode === 'dateFirst') {
      const formatDateDisplay = this.$i18n.locale === 'th' ? 'ddd DD MMM YYYY' : 'ddd MMM DD, YYYY';
      // const formattedDate = moment(start_datetime).toISOString().slice(0, 10);

      this.selectedDate = moment(start_datetime).format(formatDateDisplay);
      this.dateFilter = start_datetime;
      this.selectDateFilter = start_datetime;

      const storedDetail = JSON.parse(localStorage.getItem('detailForm'));
      // const time = new Date(start_datetime);
      // const time_end = new Date(end_datetime);

      // const hour = time.getHours();
      // const minute = time.getMinutes();

      // const formattedTime = `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`;

      this.selectedTime = {
          text: storedDetail.time.text,
          hour: storedDetail.time.hour,
          minute: storedDetail.time.minute
      };

      this.date = new Date(start_datetime)
      console.log('this.selectedDate' ,this.date)
      this.calendarFocus = moment.utc(start_datetime).local().format('YYYY-MM-DD');
      this.$nextTick(() => {
        const calendar = this.$refs.calendar;
        calendar.move(this.calendarFocus);
      })
      // this.resetMaidsList();
      // await this.getListMaidsAdminRecommend();
      // await this.loadMoreMaids();
    }
    // else if(date) {
    //   const formatDateDisplay = this.$i18n.locale === 'th' ? 'ddd DD MMM YYYY' : 'ddd MMM DD, YYYY';
    //   const formattedDate = moment(date).toISOString().slice(0, 10);

    //   this.selectedDate = moment(date).format(formatDateDisplay);
    //   this.dateFilter = formattedDate;
    //   this.selectDateFilter = date;

    //   const time = new Date(date);

    //   const hour = time.getHours();
    //   const minute = time.getMinutes();

    //   const formattedTime = `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`;

    //   this.selectedTime = {
    //       text: formattedTime,
    //       hour: hour,
    //       minute: minute
    //   };
    // }
    // else {
    //   const formatDateDisplay = this.$i18n.locale === 'th' ? 'ddd DD MMM YYYY' : 'ddd MMM DD, YYYY';
    //   const formattedDate = date.toISOString().slice(0, 10);
    //   date.setUTCHours(0, 0, 0, 0);
    //   const formattedDateForm = date.toISOString();

    //   this.selectedDate =  moment(date).format(formatDateDisplay);
    //   this.selectDateFilter = formattedDateForm;
    //   this.dateFilter = formattedDate;

      // this.timeList = true;
      // this.timeOptions = this.generateTimeOptions();
    //   this.selectedTime = this.timeOptions[0];
    // }

    await this.getListMaidsAdminRecommend();
    await this.loadMoreMaids();
  }

  async loadMoreMaids() {
    if (this.waiting) return
    this.waiting = true
    if (this.isLoggedIn) {
      await this.getMaidListWithFriends()
    } else {
      await this.getMaidList()
    }
    this.waiting = false
  }

  visibilityChanged(isVisible) {
    if (!isVisible) {
      return
    }
    this.loadMoreMaids()
  }

  get total_maids() {
    return this.isLoggedIn ? this.totalMaidsFriend : this.totalMaids
  }

  async getMaidList() {
    let options = {
      ...this.baseOptions,
      ...this.withDateTimeOptions,
      ...this.withLocationOptions,
      // ...this.withBringEquipmentOptions,
      page: this.currentMaidsPage + 1,
    }

    options = pick(options, this.params) // pick by whitelist keys
    options = omitBy(options, (val) => !val) // emit falsy
    const { page_count } = await this.getMaids({ options })
    if(page_count){
      this.totalMaidPages = page_count
      this.currentMaidsPage += 1
      this.isLastPageOfTotalMaid = this.currentMaidsPage === page_count || page_count === 0
    } else {
      this.isLastPageOfTotalMaid = true
    }
  }

  async getListMaidsAdminRecommend() {
    let options

    if (this.isLoggedIn) {
      options = {
        ...this.getMaidListWithFriendsOptions(),
        ...this.withDateTimeOptions,
        ...this.withLocationOptions,
        // ...this.withBringEquipmentOptions,
        page: 1,
      }
    } else {
      options = { 
        ...this.baseOptions,
        ...this.withDateTimeOptions,
        ...this.withLocationOptions,
        // ...this.withBringEquipmentOptions,
        page: 1 
      }
    }

    options = pick(options, this.params)
    options = omitBy(options, (val) => !val)
    await this.getMaidsAdminRecommend({ options, isLoggedIn: this.isLoggedIn })
  }

  async onOptionsChange(options) {
    this.isLoading = true;
    this.totalMaidPages = -1
    this.currentMaidsPage = 0
    this.totalMaidFriendPage = -1
    this.currentMaidsFriendPage = 0
    let tempOptions = { ...options }
    if (this.$route.query.services) {
      if (
        this.$route.query.services == 'DRAPERY' ||
        this.$route.query.services == 'CLEANING_DRAPERY'
      ) {
        tempOptions.canIron = true
      }
    }

    if (this.withBringEquipmentOptions.bring_equipment) {
      tempOptions.bring_equipment = true
    }

    this.filterData = tempOptions
    this.resetMaidsList()
    await this.getListMaidsAdminRecommend()
    await this.loadMoreMaids()
    this.isLoading = false;
  }

  async getMaidListWithFriends() {
    if (this.isLoggedIn) {
      let options = {
        ...this.getMaidListWithFriendsOptions(),
        ...this.withDateTimeOptions,
        ...this.withLocationOptions,
        // ...this.withBringEquipmentOptions,
        page: this.currentMaidsFriendPage + 1,
      }

      options = pick(options, this.params) // pick by whitelist keys
      options = omitBy(options, (val) => !val) // emit falsy values
      const { page_count } = await this.getMaidsFriend({ options })

      if(page_count){
        this.totalMaidFriendPage = page_count
        this.currentMaidsFriendPage += 1
        this.isLastPageOfMaidFriend = this.currentMaidsFriendPage === page_count || page_count === 0
      } else {
        this.isLastPageOfMaidFriend = true
      }
    }
  }

  @Watch('selectedTime')
  async onTimeChange(value) {
    this.isLoading = true;
    console.log('Time selected:', value);

    this.totalMaidPages = -1
    this.currentMaidsPage = 0
    this.totalMaidFriendPage = -1
    this.currentMaidsFriendPage = 0
    this.resetMaidsList();
    await this.getListMaidsAdminRecommend();
    await this.loadMoreMaids();
    this.isLoading = false;
  }

  get withDateTimeOptions() {
    const params = this.$route.query
    const { duration } = params

    if(this.selectDateFilter) {
      // const combinedDateTime = new Date(
      //   `${this.dateFilter}T${String(this.selectedTime.hour).padStart(2, '0')}:${String(this.selectedTime.minute).padStart(2, '0')}:00.000Z`
      // ).toISOString();
      // const combinedDateTime = `${this.dateFilter} ${String(this.selectedTime.hour).padStart(2, '0')}:${String(this.selectedTime.minute).padStart(2, '0')}:00`;
      // return { start_datetime: combinedDateTime }

      const updatedDateFilter = moment.utc(this.dateFilter).local().format('YYYY-MM-DD');
      const combinedDateTime = `${updatedDateFilter} ${String(this.selectedTime.hour).padStart(2, '0')}:${String(this.selectedTime.minute).padStart(2, '0')}:00`;
      
      return { start_datetime: combinedDateTime, duration: duration };
      // return { start_datetime: combinedDateTime };
    }

    return {}
  }

  get withLocationOptions() {
    if (!ENABLE_FILTER_MAID_BY_USER_LOCATION) {
      return {}
    }

    const params = this.$route.query
    const { location_latitude, location_longitude } = params
    if (location_latitude && location_longitude) {
      return { location_latitude, location_longitude }
    }

    return {}
  }

  get withBringEquipmentOptions() {
    const params = this.$route.query
    return { bring_equipment: parseInt(get(params, 'bring_equipment', '0')) === 1 }
  }

  goBack() {
    this.$router.go(-1)
  }

  beforeDestroy() {
    this.resetMaidsList()
    window.removeEventListener('resize', this.handleResize)
  }

  getBorderRadiusClass(index, length) {
    if (!this.showMaidList) {
      return ''
    }
    const mediaSize = window.innerWidth
    if (mediaSize < 768) {
      return index === length - 1 ? 'rounded-recommend-bottom' : ''
    }

    if (mediaSize < 992) {
      const lastRowHasOneItem = length % 2 === 1
      if (lastRowHasOneItem){
        if(index === length - 1){
          return 'rounded-recommend-bottom'
        }
        if(index === length - 2){
          return 'rounded-recommend-right'
        }
      } else {
        if(index === length - 1){
          return 'rounded-recommend-right'
        }
        if(index === length - 2){
          return 'rounded-recommend-left'
        }
      }
      return ''
    }

    if (mediaSize < 1200) {
      const lastRowHasOneItem = length % 3 === 1
      const lastRowHasTwoItem = length % 3 === 2
      if (lastRowHasOneItem){
        if(index === length - 1){
          return 'rounded-recommend-bottom'
        }
        if(index === length - 2){
          return 'rounded-recommend-right'
        }
      } else if (lastRowHasTwoItem){
        if(index === length - 1){
          return 'rounded-recommend-right'
        }
        if(index === length - 2){
          return 'rounded-recommend-left'
        }
        if(index === length - 3){
          return 'rounded-recommend-right'
        }
      } else {
        if(index === length - 1){
          return 'rounded-recommend-right'
        }
        if(index === length - 3){
          return 'rounded-recommend-left'
        }
      }
      return ''
    }

    if (mediaSize >= 1200) {
      const lastRowHasOneItem = length % 4 === 1
      const lastRowHasTwoItem = length % 4 === 2
      const lastRowHasThreeItem = length % 4 === 3
      if (lastRowHasOneItem){
        if(index === length - 1){
          return 'rounded-recommend-bottom'
        }
        if(index === length - 2){
          return 'rounded-recommend-right'
        }
      } else if (lastRowHasTwoItem){
        if(index === length - 1){
          return 'rounded-recommend-right'
        }
        if(index === length - 2){
          return 'rounded-recommend-left'
        }
        if(index === length - 3){
          return 'rounded-recommend-right'
        }
      } else if (lastRowHasThreeItem){
        if(index === length - 1){
          return 'rounded-recommend-right'
        }
        if(index === length - 3){
          return 'rounded-recommend-left'
        }
        if(index === length - 4){
          return 'rounded-recommend-right'
        }
      } else {
        if(index === length - 1){
          return 'rounded-recommend-right'
        }
        if(index === length - 4){
          return 'rounded-recommend-left'
        }
      }
      return ''
    }
  }

  resizeTimeout = null
  handleResize() {
    if (!this.resizeTimeout) {
      this.resizeTimeout = setTimeout(() => {
        this.resizeTimeout = null
        this.showMaidList = false
        setTimeout(() => {
          this.showMaidList = true
        }, 0)
      }, 100)
    }
  }

  maidKey(prefix, index) {
    return `${prefix}-${index}`
  }
}
</script>
<style lang="scss" scoped>
.admin-recommend {
  font-size: 1.2em;
  border-radius: 6px 6px 0 0;
  padding-top: 1em;
  padding-bottom: calc(1em - 10px);
}

.recommend-color {
  background-color: rgba(255, 194, 207, 0.373);
}

.recommend-card {
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
}

.rounded-recommend-right {
  border-radius : 0 0 6px 0;
}

.rounded-recommend-left {
  border-radius: 0 0 0 6px;
}

.rounded-recommend-bottom {
  border-radius: 0 0 6px 6px;
}

@media (max-width: 767px) {
  .admin-recommend {
    font-size: 1em;
  }
}
.calendar-dropdown {
  position: absolute;
  z-index: 1000;
  background-color: white;
  border: 1px solid #ccc;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
</style>